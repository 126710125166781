<template>
  <div class="position">
    <div class="positionTit">
      <div
        v-for="(item, index) in sibItem"
        :key="index"
        @click="item.path == '' ? reload() : Jump(item)"
      >
        <span v-if="index > 0">&nbsp;>&nbsp;</span>{{ $i18n.locale == 'zh' ? item.title_zh : item.title_en }}
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：当前位置
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-04-02 15:00
 */
export default {
  inject: ["reload"], // 刷新页面
  props: {
    sibItem: Array, //这样可以指定传入的类型，如果类型不对，会警告
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 返回
    Jump(item) {
      if (item.path != "") {
        this.$router.push({
          path: item.path,
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.position {
  text-align: left;
  background: #1a2a60;
  padding: 10px 260px;
  .positionTit {
    display: flex;
    align-items: center;
    div {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.7;
      cursor: pointer;
      > span {
      }
    }
  }
}
</style>