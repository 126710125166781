<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outerOne">
      <div>
        <img src="@/assets/img/dsBJ5.png" alt="" />
        <div>
          <div class="oneTitle"> {{ $t("materialPackaging.MP_oneTitle") }} </div>
          <div class="oneCont"> {{ $t("materialPackaging.MP_oneCont") }} </div>
        </div>
      </div>
    </div>
    <div class="outerTwo">
      <div class="twoTip twoTipOne">
        <div class="twoTipOne">{{ $t("materialPackaging.MP_twoTipOne") }}</div>
        <div class="twoTipTwo">{{ $t("materialPackaging.MP_twoTipTwo") }}</div>
      </div>
      <div class="twoCont twoContOne settingStyle" :style="'backgroundImage:url(' + require('@/assets/img/mpImg1.png') + ')'
        ">
        <div class="cont1">
          <div v-html="$t('materialPackaging.MP_twoModular[0]')"></div>
          <div v-html="$t('materialPackaging.MP_twoModular[1]')"></div>
        </div>
        <div class="cont2" v-html="$t('materialPackaging.MP_twoModular[2]')"></div>
        <div class="cont3">
          <div v-html="$t('materialPackaging.MP_twoModular[3]')"></div>
          <div v-html="$t('materialPackaging.MP_twoModular[4]')"></div>
        </div>
        <div class="cont4" v-html="$t('materialPackaging.MP_twoModular[5]')"></div>
        <div class="cont5">
          <div v-html="$t('materialPackaging.MP_twoModular[6]')"></div>
          <div v-html="$t('materialPackaging.MP_twoModular[7]')"></div>
        </div>
      </div>
    </div>
    <div class="outerThree">
      <div class="threeTip threeTipOne"> {{ $t("materialPackaging.MP_threeTipOne") }} </div>
      <div class="threeCont">
        <div class="threeContOne">
          <div class="tContOne">
            <div>
              <div class="contXian"></div>
              <div class="contTitle"> {{ $t("materialPackaging.MP_threeContOne") }} </div>
            </div>
          </div>
          <img src="@/assets/img/mpImg2.png" alt="" class="mpImg2" />
          <div class="contTitleTwo"> {{ $t("materialPackaging.MP_threeContTwo") }} </div>
        </div>
        <div class="threeContTwo threeContOne settingStyle" :style="'backgroundImage:url(' + require('@/assets/img/mpImg3.png') + ')'
          ">
          <div class="tCont1" v-html="$t('materialPackaging.MP_threeModular[0]')"></div>
          <div class="tCont2">
            <div v-html="$t('materialPackaging.MP_threeModular[1]')" class="tItem1"></div>
            <div v-html="$t('materialPackaging.MP_threeModular[2]')" class="tItem2"></div>
            <div v-html="$t('materialPackaging.MP_threeModular[3]')"></div>
          </div>
          <div class="tCont3">
            <div v-html="$t('materialPackaging.MP_threeModular[4]')"></div>
            <div v-html="$t('materialPackaging.MP_threeModular[5]')"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="outerFour">
      <div class="threeTip fourTipOne"> {{ $t("materialPackaging.MP_fourTipOne") }} </div>
      <div class="fourCont fourContOne">
        <div class="fourImg">
          <img src="@/assets/img/mpImg4.png" alt="" class="mpImg4" />
          <img src="@/assets/img/mpImg5.png" alt="" class="mpImg5" />
        </div>
        <div class="foutTitle"> {{ $t("materialPackaging.MP_fourContTwo") }} </div>
      </div>
    </div>
    <div class="outerFour">
      <div class="threeTip fiveTipOne"> {{ $t("materialPackaging.PPD_fiveTip") }} </div>
      <div class="fiveCont fiveContOne">
        <img src="@/assets/img/mpImg6.png" alt="" />
        <div>{{ $t("materialPackaging.PPD_fiveCont") }}</div>
      </div>
    </div>
    <div class="outerFour">
      <div class="threeTip sixTipOne"> {{ $t("materialPackaging.PPD_sixTip") }} </div>
      <div class="sixCont sixContOne">
        <div class="sContTip"> {{ $t("materialPackaging.PPD_sixContOne") }} </div>
        <div class="sContOne">
          <div class="sContOneCont">
            <div id="LBChart" :style="{ width: '100%', height: '100%' }"></div>
          </div>
          <div class="sContTwoCont">
            <ul>
              <li> {{ $t("materialPackaging.PPD_sixContTwo") }} </li>
              <li> {{ $t("materialPackaging.PPD_sixContThree") }} </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="outerFour">
      <div class="threeTip sevenTipOne"> {{ $t("materialPackaging.PPD_sevenTip") }} </div>
      <div class="sixCont sevenContOne">
        <div class="sContTip"> {{ $t("materialPackaging.PPD_sevenContOne") }} </div>
        <div class="sContOne">
          <div class="sContOneCont">
            <div id="LBChartTwo" :style="{ width: '100%', height: '100%' }"></div>
          </div>
          <div class="sContTwoCont seContTwo">
            <ul>
              <li> {{ $t("materialPackaging.PPD_sevenContTwo") }} </li>
              <li> {{ $t("materialPackaging.PPD_sevenContThree") }} </li>
              <li> {{ $t("materialPackaging.PPD_sevenContFour") }} </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：新材料与国产装备验证部
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-10-09 14:37
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "技术研发",
          title_en: "Technology R & D",
          path: "TechnologyRD",
        },
        {
          title_zh: "部门设置",
          title_en: "Department settings",
          path: "departmentSettings",
        },
        {
          title_zh: "材料与装备部",
          title_en: "Materials and Equipment Department",
          path: "",
        },
      ],
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollbox, true);
    this.handleScrollboxNow();

    this.initCharts();
  },
  methods: {
    /**
     * 动画效果
     * 刘嘉鑫
     * 2022-9-29
     */
    handleScrollboxNow() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll >= this.$(".outerOne").offset().top - 950) {
        this.$(".outerOne>div").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".twoTipOne").offset().top - 600) {
        this.$(".twoTipOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".twoContOne").offset().top - 580) {
        this.$(".twoContOne").addClass("showDiv");
      }
    },
    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置

      if (this.currentScroll >= this.$(".threeTipOne").offset().top - 600) {
        this.$(".threeTipOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".threeContOne").offset().top - 580) {
        this.$(".threeContOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourTipOne").offset().top - 600) {
        this.$(".fourTipOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fourContOne").offset().top - 580) {
        this.$(".fourContOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fiveTipOne").offset().top - 600) {
        this.$(".fiveTipOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".fiveContOne").offset().top - 580) {
        this.$(".fiveContOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".sixTipOne").offset().top - 600) {
        this.$(".sixTipOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".sixContOne").offset().top - 580) {
        this.$(".sixContOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".sevenTipOne").offset().top - 600) {
        this.$(".sevenTipOne").addClass("showDiv");
      }

      if (this.currentScroll >= this.$(".sevenContOne").offset().top - 580) {
        this.$(".sevenContOne").addClass("showDiv");
      }
    },

    initCharts() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("LBChart"));
      // 绘制图表
      myChart.setOption({
        title: {
          // text: "Referer of a Website",
          // subtext: "Fake Data",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //   orient: "vertical",
        //   left: "left",
        // },
        color: [
          "#B6520F",
          "#404040",
          "#4472C4",
          "#ED7D31",
          "#A5A5A5",
          "#FFC000",
          "#5B9BD5",
          "#70AD47",
          "#2F5597",
        ], // 自定义颜色范围
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            label: {
              normal: {
                formatter: "{b} {d}%",
                textStyle: {
                  fontWeight: "normal",
                  fontSize: this.nowSize(14),
                },
              },
            },
            data: [
              { value: 5, name: "其他材料" },
              { value: 3, name: "溅射靶材" },
              { value: 41, name: "硅材料" },
              { value: 12, name: "光掩模" },
              { value: 12, name: "电子气体" },
              { value: 8, name: "光刻胶配套试剂" },
              { value: 7, name: "CMP 抛光材料" },
              { value: 6, name: "光刻胶" },
              { value: 6, name: "工艺化学品" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
      // 基于准备好的dom，初始化echarts实例
      let myChartTwo = this.$echarts.init(
        document.getElementById("LBChartTwo")
      );
      // 绘制图表
      myChartTwo.setOption({
        title: {
          // text: "Referer of a Website",
          // subtext: "Fake Data",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //   orient: "vertical",
        //   left: "left",
        // },
        color: [
          "#4472C4",
          "#ED7D31",
          "#A5A5A5",
          "#FFC000",
          "#5B9BD5",
          "#70AD47",
          "#264478",
          "#9E480E",
          "#636363",
        ], // 自定义颜色范围
        series: [
          {
            name: "",
            type: "pie",
            radius: "50%",
            label: {
              normal: {
                formatter: "{b} {d}%",
                textStyle: {
                  fontWeight: "normal",
                  fontSize: this.nowSize(14),
                },
              },
            },
            data: [
              { value: 4, name: "炉式设备" },
              { value: 18, name: "化学气相沉积设备" },
              { value: 19, name: "光刻机" },
              { value: 23, name: "刻蚀设备" },
              { value: 3, name: "离子注入设备" },
              { value: 7, name: "物理气相沉积设备" },
              { value: 4, name: "化学机械研磨设备" },
              { value: 8, name: "清洗设备" },
              { value: 14, name: "前道里测设" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
      //多图表自适应
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    nowSize(val, initWidth = 1920) {
      //现在很多浏览器都是1920*1080，initWidth值可根据自己需求进行修改
      var nowClientWidth = document.documentElement.clientWidth;
      console.log(val * (nowClientWidth / initWidth));
      return val * (nowClientWidth / initWidth);
    },
  },
};
</script>
<style lang='scss' scoped>
.appCont {
  background: #f6f6f6;
}

.outerOne {
  padding: 80px 310px;

  >div {
    display: flex;
    align-items: flex-start;
    transition: all 0.7s ease-in 0s;
    transform: translateY(20px);
    opacity: 0;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 30px;
    }

    div {
      .oneTitle {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        margin-bottom: 30px;
      }

      .oneCont {
        font-size: 18px;
        font-weight: 400;
        color: #222222;
        line-height: 32px;
      }
    }
  }
}

.twoTipOne,
.twoContOne,
.threeTipOne,
.threeContOne,
.fourTipOne,
.fourContOne,
.fiveTipOne,
.fiveContOne,
.sixTipOne,
.sixContOne,
.sevenTipOne,
.sevenContOne {
  transition: all 0.6s ease-in 0s;
  transform: translateY(30px);
  opacity: 0;
}

.outerTwo {
  background: #ffffff;
  padding: 59px 357px 20px;

  .twoTip {
    margin-bottom: 39px;

    .twoTipOne {
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
      margin-bottom: 16px;
      text-align: center;
    }

    .twoTipTwo {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #222222;
      text-align: center;
    }
  }

  .twoCont {
    background-size: 100% 100% !important;
    width: 69%;
    height: 510px;
    object-fit: inherit !important;
    margin: 0 auto;

    >div {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: inline-block;
      text-align: center;
    }

    .cont1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 70px 125px 0 110px;
      margin-bottom: 60px;
    }

    .cont2 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 110px;
      margin-bottom: 20px;
      font-weight: bold !important;
      font-size: 20px !important;
    }

    .cont3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 66px 0 64px;
      margin-bottom: 30px;
    }

    .cont4 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 100px;
      margin-bottom: 50px;
      font-weight: bold !important;
      font-size: 20px !important;
    }

    .cont5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 124px 0 124px;
    }
  }
}

.threeTip {
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #222222;
  padding: 80px 310px 60px;
}

.outerThree {
  .threeCont {
    background: #ffffff;
    padding: 70px 375px;
    display: flex;
    justify-content: space-between;

    .tContOne {
      margin: 0 0 32px 38px;
      display: inline-block;

      >div {
        background: #07257d;
        display: flex;
        align-items: center;
      }

      .contXian {
        width: 11px;
        height: 41px;
        background: #d80040;
        margin-right: 38px;
        display: inline-block;
      }

      .contTitle {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        display: inline-block;
        padding-right: 40px;
      }
    }

    .mpImg2 {
      width: 300px;
      height: 310px;
      margin-left: 34px;
    }

    .contTitleTwo {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #202020;
      line-height: 36px;
      margin-top: 24px;
      width: 62%;
    }

    .threeContTwo {
      width: 46%;
      height: 442px;
      display: flex;
      flex-direction: column;

      >div {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
      }

      .tItem1 {
        padding-top: 10px;
      }

      .tItem2 {
        font-size: 20px !important;
        font-family: Microsoft YaHei;
        font-weight: bold !important;
        color: #282828 !important;
        padding-top: 25px;
      }

      .tCont1 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 66px 0 62px;
      }

      .tCont2 {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 90px;
      }

      .tCont3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 148px 0 144px;
        margin-bottom: 88px;
        margin-top: auto;
      }
    }
  }
}

.outerFour {
  .fourCont {
    background: #ffffff;
    padding: 23px 290px 80px;

    .fourImg {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mpImg4 {
        width: 60%;
        // height: 437px;
        object-fit: contain !important;
      }

      .mpImg5 {
        width: 24%;
        height: 280px;
        object-fit: contain !important;
      }
    }

    .foutTitle {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #222222;
      line-height: 36px;
    }
  }

  .fiveCont {
    background: #ffffff;
    padding: 13px 321px 73px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 380px;
      // height: 254px;
    }

    div {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #222222;
      line-height: 36px;
      width: 58%;
      margin-top: 30px;
    }
  }

  .sixCont {
    background: #ffffff;
    padding: 108px 310px 65px;

    .sContTip {
      background: #c53535;
      padding: 12px 21px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      display: inline-block;
      margin-left: 136px;
    }

    .sContOne {
      display: flex;
      align-items: center;

      >div {
        height: 400px;
      }

      .sContOneCont {
        border: 1px solid #7d88a8;
        width: 48%;
      }

      .sContTwoCont {
        background: #07257d;
        width: 52%;
        padding: 1px 0;

        ul {
          display: flex;
          flex-direction: column;
          padding: 124px 60px 60px;

          li {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 36px;
            margin-bottom: 60px;
          }

          >li:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .seContTwo {
      ul {
        padding: 80px 60px 60px !important;
      }
    }
  }
}
</style>